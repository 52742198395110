import React, { Component } from 'react';
import isEmpty from 'lodash.isempty';
import normalize from 'us-states-normalize';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import { clearEmployee } from '../../utils.js';
import { AuthUserContext } from '../Session';

import { isAdmin } from '../../utils.js';

class Card extends Component {
	constructor(props) {
		super(props);
		this.state = {
			employees: props.employees,
			employeeId: props.employeeId,
			employee: props.employee
		};
		this.imgRef = React.createRef();
		this.cardRef = React.createRef();
	}

	getEmployeeIndex = (employeeId) => {
		const employees = this.state.employees;
		const index = employees.findIndex(object => object.id === employeeId);
		return index;
	}

	hasNewEmployee = (num) => {
		const employees = this.state.employees;
		const currentEmployeeId = this.state.employeeId;
		let index = -1;

		if (currentEmployeeId !== '') {
			index = this.getEmployeeIndex(currentEmployeeId);
		}

		const newEmployee = employees[index + num];

		if (newEmployee) {
			const newEmployeeId = newEmployee.id;
			return newEmployeeId;
		} else {
			return false;
		}
	}

	getNewEmployee = (num) => {
		const newEmployeeId = this.hasNewEmployee(num);

		if (newEmployeeId) {
			if (this.imgRef.current) {
				this.imgRef.current.style.opacity = '0';
			}
			this.props.setEmployeeState(newEmployeeId);
			document.getElementById(newEmployeeId+'-marker').click();
			if (this.cardRef.current) { this.cardRef.current.scrollTo(0, 0); }
		}
	}

	reset = () => {
		this.props.setEmployeeState('');
		window.dispatchEvent(new Event('resize'));
		clearEmployee();
	}

	handleImageLoad = (event) => {
		event.target.style.opacity = '1';
	}

	componentDidUpdate() {
		if (this.state.employee !== this.props.employee) {
			this.setState({ employee: this.props.employee });
		}

		if (this.state.employeeId !== this.props.employeeId) {
			this.setState({ employeeId: this.props.employeeId });
		}
	}

	render() {
		const { employees, employee, employeeId } = this.state;
		
		return (
			<aside className="card">
				<div className="employee">				
					{!isEmpty(employees) && !isEmpty(employee) ?
						<EmployeeCard employeeData={employee} handleImageLoad={this.handleImageLoad} cardRef={this.cardRef} imgRef={this.imgRef} />
					:
						<div className="employee-card placeholder" id="placeholder-card">
							<h2>Get to know the employees!</h2>

							<p>A strength of 1517 Media&rsquo;s culture is a sense of strong community. Click on the red stars on the map to learn more about your fellow 1517 Media employees or click start to view all employees.</p>

							<button className="start" onClick={() => this.getNewEmployee(1)}>Start</button>
						</div>
					}
				</div>

				<div className="card-nav">
					{ this.hasNewEmployee(-1) ? <button className="prev" onClick={() => this.getNewEmployee(-1)}>Previous</button> : <button className="disabled" /> }
					{ employeeId !== '' ? <button className="reset" onClick={this.reset}>Reset</button> : <button className="disabled" /> }
					{ this.hasNewEmployee(1) ? <button className="next" onClick={() => this.getNewEmployee(1)}>Next</button> : <button className="disabled" /> }
				</div>
			</aside>
		)
	}
}

const EmployeeCard = (props) => {
	const data = props.employeeData;
	const email = data.email;
	const employeeId = data.id;
	const firstName = data.firstName;
	const lastName = data.lastName;
	const city = data.city;
	const state = normalize(data.state) ? normalize(data.state, { returnType: 'name' }) : data.state;
	const position = data.position;
	const like = data.like;
	const firstJob = data.firstJob;
	const books = data.books;
	const newSkill = data.newSkill;
	const pauseRewind = data.pauseRewind;
	const imgUrl = data.imgUrl;
	const imgDescription = data.imgDescription;

	const isMobile = useMediaQuery({
		query: "(max-width: 991px)"
	});
	
	const Image = () => (
		<img
			ref={props.imgRef}
			src={imgUrl}
			style={{ opacity: '0', transition: 'ease 0.5s opacity' }}
			alt={`${firstName} ${lastName}`}
			onLoad={props.handleImageLoad}
		/>
	);

	const Content = () => (
		<AuthUserContext.Consumer>
			{authUser =>
				<div className="content">
					{ !!isAdmin(authUser) ? <Link className="employee-edit" to={`/edit/${employeeId}`}>Edit</Link> : null }
					<h2>{firstName} {lastName}</h2>
					<h3>{city}, {state}</h3>
					<h4>{position}</h4>

					<p className="question">What do you like about 1517 Media?</p>
					<p className="answer">{like}</p>

					<p className="question">What was your first job ever?</p>
					<p className="answer">{firstJob}</p>

					<p className="question">What books are on your nightstand?</p>
					<p className="answer">{books}</p>

					<p className="question">If you could gain a new skill in 10 minutes, what would it be?</p>
					<p className="answer">{newSkill}</p>

					<p className="question">Would you rather have a pause or rewind button in your life and why?</p>
					<p className="answer">{pauseRewind}</p>

					<p className="question">A little bit about my photo.</p>
					<p className="answer">{imgDescription}</p>
				</div>
			}
		</AuthUserContext.Consumer>
	);

	return (
		<div className="employee-card employee-active" ref={props.cardRef} id={employeeId} data-username={employeeId} data-email={email}>
			{ isMobile ? <><Content /><Image /></> : <><Image /><Content /></> }
		</div>
	);
};

export default Card;